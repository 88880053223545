(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var $ = require('jquery');

/**
 * A small wrapper around Flickr interactions.
 */
module.exports = {

  init: function(opts) {
    this.USER_ID = opts.user_id;
    this.API_KEY = opts.api_key;
  },

  /**
   * Useful Functionality Stuffs
   * ===========================
   */

  /**
   * Get stuff from the Flickr API.
   * https://www.flickr.com/services/api/
   *
   * @param {string} method - any flickr API method.
   * @param {object} opts   - any of the options for the API method.
   * @return {$promise}     - a jQuery ajax promise.
   *
   * @example
   *   flickr.get('flickr.people.getInfo', {
   *
   *     user_id: this.flickr.USER_ID
   *
   *   }).done(function(data, res) {
   *
   *     $bios.text(data.person.description._content);
   *
   *   });
   */

  get: function(method, opts) {

    // Set some defaults.
    opts.api_key = this.API_KEY;
    opts.format = 'json';
    opts.method = method;

    return $.ajax({
      type: 'GET',
      url: 'https://api.flickr.com/services/rest/',
      dataType: 'jsonp',
      jsonp: 'jsoncallback',
      data: opts
    });
  }

};

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/flickr/index.js","/flickr")