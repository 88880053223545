(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var React = require('react');
var flickr = require('../../flickr');
var MasonryMixin = require('react-masonry-mixin');

var masonryOptions = {
  columnWidth: 100,
  isFitWidth: true,
  gutter: 20
};

module.exports = React.createClass({
  displayName: 'FlickrGallery',

  mixins: [MasonryMixin('masonryGallery', masonryOptions)],

  /**
   * Schema for component data
   */
  getInitialState: function() {
    return {images: []};
  },

  /**
   * Make AJAX request to flickr for photos after mount and initialize
   * masonry.
   */
  componentDidMount: function() {

    var self = this;
    flickr.get('flickr.photosets.getPhotos', {

      photoset_id: self.props.flickrGalleryId,
      extras: 'url_s,url_m'

    }).done(function(data, res) {
      self.setState({images: data.photoset.photo});

      $(self.getDOMNode())
        .find('.boxer-init')
        .boxer();
    });

  },

  /**
   * Render images and display in Masonry gallery.
   */
  render: function () {
    var childElements = this.state.images.map(function (image) {
      return (
        React.createElement("div", {className: "item"}, 
          React.createElement("a", {className: "boxer-init", href:  image.url_m}, 
            React.createElement("img", {src:  image.url_s})
          )
        )
      );
    });

    return (
      React.createElement("div", {id: "gallery", ref: "masonryGallery"}, 
        childElements
      )
    );
  },
});

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/react-flickr-gallery/index.js","/components/react-flickr-gallery")