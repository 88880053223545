(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var flickr = require('./flickr');
var $ = require('jquery');
var React = require('react');
var Carousel = require('./components/react-flickr-tinycarousel');
var FlickrGallery = require('./components/react-flickr-gallery');
var FlickrThumb = require('./components/react-flickr-thumb');
//var Gallery = require('react-component-gallery');
var thidemann = {};

/**
 * Initialize the flickr stuff.
 */
flickr.init({
  user_id: '33048781@N03',
  api_key: 'ebda5e289799637550c7248a28632fc9'
});

/**
 * Photo set IDs set on the flickr object.
 */
flickr.galleries = {
  recent_paintings: '72157671202040717',
  old_paintings: '72157701273083425',
  // drawings: '72157610724960134',
  tattoo: '72157671201166907',
  flash: '72157698062964032'
};

flickr.carousels = {
  featured: '72157699684968121'
};

/**
 * Thidemann global functionality
 */
thidemann = {
  /**
   * Initialize Thidemann functionality
   */
  init: function() {

    thidemann.carousels();
    thidemann.mainMenu();
    thidemann.bio();
    thidemann.galleries();
    thidemann.thumbs();
  },

  /**
   * Global Navigation
   */
  mainMenu: function() {
    var $mainMenu = $('#main-menu'),
        $link = $mainMenu.find('a'),
        $mainMenuTrigger = $('#mobile-menu-trigger'),
        $mainMenuTriggerIcon = $('.menu-toggle-icon');

    $mainMenu
      .find('.depth--1 > .nav-link')
      .on('mouseenter', function(e) {
        thidemann.sizeDropdownTriggers($mainMenu);
        thidemann.menuEdgeDetection($(this).parent().find('> ul'), e);
      });

    // Main Menu Mobile Trigger
    $mainMenuTrigger.on('click', function(e) {
      $mainMenu.toggleClass('is-expanded');
      $mainMenuTriggerIcon.toggleClass('is-expanded');
    });
  },

  /**
   * Find and fill `data-bio` elements with the user's Flickr bio.
   */
  bio: function() {
    var $bios = $('[data-bio]');

    flickr.get('flickr.people.getInfo', {

      user_id: flickr.USER_ID

    }).done(function(data, res) {

      $bios.text(data.person.description._content);

    });
  },

  carousels: function() {

    $.each(Object.keys(flickr.carousels), function(i, name) {

      $('[data-carousel-' + name + ']').each(function() {
        React.render(
          React.createElement(Carousel, {flickrGalleryId: flickr.carousels[name]}),
          this
        );
      });

    });
  },

  /**
   * Load all the photosets into their corresponding elements.
   */
  galleries: function() {
    $.each(Object.keys(flickr.galleries), function(i, name) {
      $('[data-gallery-' + name + ']').each(function() {
        React.render(
          React.createElement(FlickrGallery, {flickrGalleryId:  flickr.galleries[name] }),
          this
        );
      });
    });
  },

  thumbs: function() {
    $('.gallery-thumb').each(function() {
      var data = $(this).data();

      React.render(
        React.createElement(FlickrThumb, {flickrPhotoData: data }),
        this
      );
    });
  }
};

$(window).load(thidemann.init);

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_79b9deff.js","/")