(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
var React = require('react');
var flickr = require('../../flickr');

module.exports = React.createClass({
    displayName: 'FlickrThumb',

    /**
     * Schema for component data
     */
    getInitialState: function () {
      return {image: {}};
    },

    /**
     * Make AJAX request to flickr for photos after mount and initialize
     * masonry.
     */
    componentDidMount: function () {

      var self = this;
      var photoId;
      var galleryId;


      galleryId = flickr.galleries[self.props.flickrPhotoData.gallery];

      flickr.get('flickr.photosets.getInfo', {
        user_id: flickr.USER_ID,
        photoset_id: galleryId

      }).done(function (data, res) {

        photoId = data.photoset.primary;
        self.setState({title: data.photoset.title._content});

        flickr.get('flickr.photos.getInfo', {
          photo_id: photoId

        }).done(function (data, res) {

          self.setState({image: data.photo});

          $(self.getDOMNode())
            .find('.gallery-photo')
            .boxer();
        });

      });
    },


    /**
     * Render images and display in Masonry gallery.
     */
    render: function () {

      var path,
          image,
          title,
          url;

      image = this.state.image;
      title = this.state.title;
      url   = this.props.flickrPhotoData.url;

      path  = 'https://farm' + image.farm;
      path += '.staticflickr.com/' + image.server;
      path += '/' + image.id + '_' + image.secret + '_' + 'n' + '.jpg';

      return (
          React.createElement("div", {className: "gallery-photo-item"}, 
            React.createElement("a", {href: url}, React.createElement("img", {className: "gallery-photo", src: path})), 
            React.createElement("h3", null, React.createElement("a", {href: url}, title))
          )
      )
    }
});
}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/react-flickr-thumb/index.js","/components/react-flickr-thumb")