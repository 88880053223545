(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
/**
 * This depends on jcarousel being available
 */

var React = require('react');
var flickr = require('../../flickr');

var Carousel = React.createClass({displayName: "Carousel",

  /**
   * Schema for component data
   */
  getInitialState: function() {
    return {images: []};
  },

  /**
   * Make AJAX request to flickr for photos after mount and initialize
	 * jcarousel.
   */
  componentDidMount: function() {

		var self = this;
    flickr.get('flickr.photosets.getPhotos', {

      photoset_id: self.props.flickrGalleryId,
      extras: 'url_m'

    }).done(function(data, res) {

      self.setState({images: data.photoset.photo});
			$(self.getDOMNode())
				 .on('jcarousel:create jcarousel:reload', function() {
					 var element = $(this),
					 width = element.innerWidth();

					 // This shows 1 item at a time.
					 // Divide `width` to the number of items you want to display,
					 // eg. `width = width / 3` to display 3 items at a time.
					 element.jcarousel('items').css('width', width + 'px');
				 })
				 .jcarousel({
					 // Your configurations options
					 animation: 'slow',
				 })
				 .jcarouselAutoscroll({
					 interval: 5000,
					 target: '+=1',
					 autostart: true
				 });

			 /**
				* Controls
				*/
			 $('.prev').jcarouselControl({
				 target: '-=1'
			 });

			 $('.next').jcarouselControl({
				 target: '+=1'
			 });

    });

  },

  render: function() {
    /**
     * Modify this however you want break it even, I don't care.
     *
		 * If you want to add a class use `className` don't ask questions
		 * it's a react thing.
     */
    return (
      React.createElement("div", {className: "jcarousel carousel--wrapper"}, 
          React.createElement("ul", null, 
            this.state.images.map(function(image) {
              return (
								React.createElement("li", {className: "carousel--item", key:  image.url_m}, 
								  React.createElement("img", {src:  image.url_m})
								)
							);
            })
          ), 
        React.createElement("a", {className: "carousel--control prev", href: "#"}, "‹"), 
        React.createElement("a", {className: "carousel--control next", href: "#"}, "›")
      )
    );
  }

});

module.exports = Carousel;

}).call(this,require("1YiZ5S"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/components/react-flickr-tinycarousel/index.js","/components/react-flickr-tinycarousel")